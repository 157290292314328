













































































































import { AsyncComponent, VueConstructor } from 'vue'
import { Component, Mixins } from 'vue-property-decorator'

import { AuthControlServiceType, IAuthControl } from '../../../../../auth/contracts'
import { AuthMixin, LogoutMixin } from '../../../../../auth/shared'
import { ContentData } from '../../../../../../contexts'
import { Inject } from '../../../../../../support'
import { RouteNames as ProfileRoutes } from '../../../../../profile/routes'

import { SearchResults } from '../../../SearchResults'

import { NavbarBaseMixin } from '../../Navbar.mixin'
import { NavbarItem } from '../../Navbar.contracts'
import { navbarSnippetsRegistry } from '../../Navbar.config'

import MobileBottomMenu from '../MobileBottomMenu.vue'
import LanguagesDropdown from '../LanguagesDropdown.vue'
import Logo from '../../../../molecules/Logo/Logo.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NavbarMobileSimple>({
  name: 'NavbarMobileSimple',
  components: { Logo, MobileBottomMenu, LanguagesDropdown, SearchResults },
  created () {
    this.checkIfIsPageWithoutMobileNav()
  }
})
export class NavbarMobileSimple extends Mixins(AuthMixin, LogoutMixin, NavbarBaseMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  public simpleSearch: boolean = true

  public get profileSnippetModules (): ContentData['modules'] | undefined {
    if (!this.profileLinks) {
      return
    }

    const linkWithSnippets = this.profileLinks.find((link) => link.snippet)

    if (!linkWithSnippets) {
      return
    }

    return linkWithSnippets.snippet?.modules
  }

  public get profileMenu (): NavbarItem[] {
    return [
      {
        target: { name: `profile.${ProfileRoutes.Account}` },
        label: this.$t('front.shared.navbar.NavbarMobileSimple.account'),
        children: []
      },
      ...this.profileLinks ?? []
    ]
  }

  public getModule (type: string): VueConstructor | AsyncComponent {
    return navbarSnippetsRegistry[type]
  }
}

export default NavbarMobileSimple
