export const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  order: 849,
  requires: ['computeStyles'],
  fn: (data: any) => {
    const width = data.offsets.reference.width

    data.styles.width = `${width}px`
    data.offsets.popper.width = width

    return data
  }
}
