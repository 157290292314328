





















import { Component, Prop, Vue } from 'vue-property-decorator'

import { UiMarkdown } from '../../../../../modules/partials/UiMarkdown'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'TopBar', components: { UiMarkdown } })
export class TopBar extends Vue {
  @Prop({ type: String, required: false })
  public readonly topBarContent?: string

  @Prop({ type: Boolean, required: true })
  public readonly showLanguageSwitcher?: boolean

  public get languages (): Record<string, string>[] {
    return [
      {
        url: '/',
        label: 'pl'
      },
      {
        url: 'https://lirene.eu/',
        label: 'en'
      },
      {
        url: 'https://lirene.ru/',
        label: 'ru'
      },
      {
        url: 'https://www.lirene.cz/',
        label: 'cz'
      }
    ]
  }

  public get hasContent (): boolean {
    return typeof this.topBarContent !== 'undefined' && this.topBarContent.length > 0
  }
}

export default TopBar
