



















import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import { Link } from '../../../../../../contexts'
import { Logo } from '../../../../molecules/Logo'

import BodyMarginMixin from '../BodyMargin.mixin.vue'
import LogoMixin from '../Logo.mixin.vue'
import { NAVBAR_COMPONENT_KEY, NAVBAR_WRAPPER_DEFAULT_CONFIG } from '../../Navbar.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<NavbarSimple>({
  name: 'NavbarSimple',
  components: { Logo },
  created (): void {
    this.config = this.getComponentConfig(
      NAVBAR_COMPONENT_KEY,
      { ...NAVBAR_WRAPPER_DEFAULT_CONFIG }
    )
  }
})
export class NavbarSimple extends Mixins(BodyMarginMixin, LogoMixin) {
  /**
   * Determines the `backTo` link.
   */
  @Prop({ type: Object, required: false })
  public readonly link?: Link

  /**
   * Reference for navigation.
   */
  @Ref('navigation-simple')
  public readonly navigationRef?: HTMLElement

  /**
   * Determines whether `backTo` link is present.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' && ('target' in this.link)
  }
}
export default NavbarSimple
