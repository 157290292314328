





















































import { Component, Mixins } from 'vue-property-decorator'

import { BaseCartMixin } from '../../../../../checkout/shared/mixins/base-cart.mixin'

import { NavbarBaseMixin } from '../../Navbar.mixin'
import { NavbarLogo } from '../../Navbar.contracts'

import LanguagesDropdown from '../LanguagesDropdown.vue'
import Logo from '../../../../molecules/Logo/Logo.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NavbarDesktopSimple>({
  name: 'NavbarDesktopSimple',
  components: { LanguagesDropdown, Logo }
})
export class NavbarDesktopSimple extends Mixins(BaseCartMixin, NavbarBaseMixin) {
  public get logos (): Array<NavbarLogo> | undefined {
    if (!Array.isArray(this.logo)) {
      return
    }

    return this.logo
  }

  public get currentPath () {
    return this.$route.path
  }
}

export default NavbarDesktopSimple
