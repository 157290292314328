
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import throttle from 'lodash/throttle'

import { UseListeners } from '../../../../../modules/mixins/UseListeners'

import {
  BODY_MARGIN_TOP_VAR,
  TOP_BAR_HEIGHT_VAR,
  TOP_BAR_SELECTOR
} from '../Navbar.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BodyMarginMixin>({
  name: 'BodyMarginMixin',
  mounted (): void {
    this.setGlobalVariables()
    this.addEventListener('resize', throttle(this.setGlobalVariables, 100))
  },
  updated (): void {
    this.setGlobalVariables()
  }
})
export class BodyMarginMixin extends UseListeners {
  /**
   * Determines whether topbar is present.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasTopBar!: boolean

  /**
   * Reference for navigation.
   */
  @Ref('navigation')
  public readonly navigationRef?: HTMLElement

  /**
   * Determines whether the CSS variable are set.
   */
  private hasVariable (demandVar: string) {
    const root: HTMLElement = document.documentElement
    return !!getComputedStyle(root).getPropertyValue(demandVar)
  }

  /**
   * Sets up needed GLOBAL css variables.
   * @private
   */
  @Watch('navigationRef')
  private setGlobalVariables (): void {
    const root: HTMLElement = document.documentElement
    if (!root) {
      return
    }

    const { navbarHeight, topBarHeight } = this.getNavbarHeight()
    root.style.setProperty(BODY_MARGIN_TOP_VAR, `${navbarHeight}px`)
    root.style.setProperty(TOP_BAR_HEIGHT_VAR, `${topBarHeight}px`)

    document.body.classList.add('body--ready')
  }

  private getNavbarHeight () {
    let navbarHeight: number = this.navigationRef ? this.navigationRef.offsetHeight : 0
    let topBarHeight: number = 0

    const topBarElement = document.querySelector(TOP_BAR_SELECTOR) as HTMLDivElement
    if (this.hasTopBar && topBarElement) {
      topBarHeight = topBarElement.offsetHeight
      navbarHeight += topBarHeight
    }

    return { navbarHeight, topBarHeight }
  }
}

export default BodyMarginMixin
