
























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { CartModel } from '../../../../checkout/contracts'
import { WishListModel } from '../../../../wishlist/contract'

import { Loader } from '../../../molecules/Loader'

import { MobileMenuItemDefinition } from '../Navbar.contracts'
import { Inject } from '../../../../../support'
import { EventbusType, IEventbus } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl) (original)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<MobileBottomMenu>({
  name: 'MobileBottomMenu',
  components: { Loader },
  mounted () {
    if (this.mobileMenuWrapperRef) {
      this.eventBus.emit('app:mobile-nav-height', this.mobileMenuWrapperRef.offsetHeight)
      this.setMobileMenuHeight(this.mobileMenuWrapperRef.offsetHeight)
    }
  },
  destroyed (): void {
    this.setMobileMenuHeight(0)
  }
})
class MobileBottomMenu extends Vue {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Prop({ type: Array, required: true })
  public elements!: Array<MobileMenuItemDefinition>

  @Prop({ type: Boolean, required: true, default: true })
  public isWaitingForAuth!: boolean

  @Prop({ type: String, required: true, default: '' })
  public accountLinkId!: string

  @Prop({ required: true, default: null })
  public selectedMenuItem!: string | null

  @Prop({ type: Boolean, required: true, default: false })
  public isLoggedInUser!: boolean

  @Prop({ type: Boolean, required: true, default: false })
  public isWishlistServiceAvailable!: boolean

  @Prop({ type: Object, required: true, default: null })
  public wishlist!: WishListModel | null

  @Prop({ required: true, default: null })
  public cart!: CartModel | null

  @Ref('mobileMenuWrapper')
  public readonly mobileMenuWrapperRef?: HTMLElement

  /**
   * Determines active icon for passed-in item.
   */
  public activeIcon (item: MobileMenuItemDefinition): string {
    if (item.id === 'cart' && this.cart && this.cart.getTotalQuantity() > 0) {
      return item.icon.active
    } else {
      return this.selectedMenuItem === item.id ? item.icon.active : item.icon.default
    }
  }

  /**
   * Determines whether render menu item badge.
   */
  public showBadge (element: MobileMenuItemDefinition): boolean {
    if (element.badge) {
      switch (element.id) {
        case 'cart':
          if (!this.cart) {
            return false
          }
          return this.cart.getTotalQuantity() > 0
        case 'favouriteProducts':
          return true
      }
    }

    return false
  }

  /**
   * Constructs the applicable badges for given element.
   */
  public constructBadge (element: MobileMenuItemDefinition): string | number | undefined {
    if (element.badge) {
      switch (element.id) {
        case 'cart':
          return this.cart !== null ? this.cart.getTotalQuantity() : 0
        case 'favouriteProducts':
          return this.isWishlistServiceAvailable && this.wishlist ? this.wishlist.itemsCount : 0
      }
    }

    return undefined
  }

  protected setMobileMenuHeight (height: number): void {
    document.documentElement.style.setProperty('--mobile-nav-height', height + 'px')
  }
}

export default MobileBottomMenu
