















import { Component, Prop, Vue } from 'vue-property-decorator'

import { NavbarLogo } from '../../organisms/Navbar/Navbar.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<Logo>({ name: 'Logo' })
export class Logo extends Vue {
  @Prop({ type: [Object, Array], required: true })
  public readonly logo!: NavbarLogo | NavbarLogo[]

  public get hasMultipleLogos (): boolean {
    return Array.isArray(this.logo) && this.logo.length > 0
  }
}

export default Logo
