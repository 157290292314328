
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { NavbarLogo, NavbarProps } from '../Navbar.contracts'
import { Inject } from '../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../contexts'
import { AuthMixin, IAuthMixin } from '../../../../auth/shared'
import { StructureConfigurable } from '../../../../../support/mixins'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<LogoMixin>({
  name: 'LogoMixin'
})
export class LogoMixin extends Mixins<IAuthMixin, StructureConfigurable>(AuthMixin,
  StructureConfigurable) {
  @Prop({ type: Object, required: false })
  public readonly brandImage?: NavbarProps['brandImage']

  @Prop({ type: Object, required: false })
  public readonly logoLink?: NavbarProps['logoLink']

  @Prop({ type: Object, required: false })
  public readonly secondaryBrandImage?: NavbarProps['secondaryBrandImage']

  @Prop({ type: Object, required: false })
  public readonly secondaryLogoLink?: NavbarProps['secondaryLogoLink']

  @Inject(SiteServiceType, false)
  public readonly siteService?: ISiteService

  public get logo (): NavbarLogo | Array<NavbarLogo> | undefined {
    if (this.shouldUseManyLogos) {
      return this.logosCollection
    } else {
      return this.logoWithPremiumConsideration
    }
  }

  /**
   * Retrieves logo considering isUserPremium state
   */
  public get logoWithPremiumConsideration (): NavbarLogo | undefined {
    let image

    if (this.isUserPremium && this.shouldSwitchLogoWhenPremium) {
      if (this.secondaryBrandImage) {
        image = {
          ...this.secondaryBrandImage,
          isLazy: false,
          alt: this.siteService?.getActiveSite().name || ''
        }
      }
    }

    if (!image) {
      if (this.brandImage) {
        image = {
          ...this.brandImage,
          isLazy: false,
          alt: this.siteService?.getActiveSite().name || ''
        }
      }
    }

    return {
      image,
      link: this.isUserPremium ? this.secondaryLogoLink : this.logoLink
    }
  }

  /**
   * Retrieves collection of both logo: main and secondary
   */
  public get logosCollection (): Array<NavbarLogo> | undefined {
    let firstImage
    let secondaryImage
    const logos = []

    if (this.brandImage) {
      firstImage = {
        ...this.brandImage,
        isLazy: false,
        alt: this.siteService?.getActiveSite().name || ''
      }

      logos.push({
        image: firstImage,
        link: this.logoLink
      })
    }

    if (this.secondaryBrandImage) {
      secondaryImage = {
        ...this.secondaryBrandImage,
        isLazy: false,
        alt: this.siteService?.getActiveSite().name || ''
      }

      logos.push({
        image: secondaryImage,
        link: this.secondaryLogoLink
      })
    }

    return logos
  }

  /**
   * Determines whether component should switch logo when user is premium
   */
  public get shouldSwitchLogoWhenPremium (): boolean {
    return this.getConfigProperty('shouldSwitchLogoWhenPremium')
  }

  /**
   * Determines whether component should use both logo regardless of user benefits stage
   */
  public get shouldUseManyLogos (): boolean {
    return this.getConfigProperty('shouldUseManyLogos')
  }
}

export default LogoMixin
