























import { Component, Prop, Vue } from 'vue-property-decorator'

import { CurrentLocale, NavbarItem } from '../Navbar.contracts'

/**
 * @author Filip Rurak <Filip.rurak@movecloser.pl
 */
@Component<LanguagesDropdown>({
  name: 'LanguagesDropdown'
})
export class LanguagesDropdown extends Vue {
  @Prop({ type: Array, required: true })
  public languagesLinks!: Array<NavbarItem>

  @Prop({ type: Object, required: true })
  public currentLocale!: CurrentLocale

  public changeLocale (e: Event, item: NavbarItem) {
    e.preventDefault()
    document.cookie = `X-Lang=${item.icon};path=/`
    window.location.replace(item.target as string)
  }
}

export default LanguagesDropdown
