

























































import { Component, Prop } from 'vue-property-decorator'

import { Loader } from '../../../../shared/molecules/Loader'
import { sameWidth } from '../../../support/popper-modifiers'

import { AbstractSearchResults } from './_abstract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResultsDesktop>({
  name: 'SearchResultsDesktop',
  components: { Loader }
})
export class SearchResultsDesktop extends AbstractSearchResults {
  @Prop({ type: Number, required: true })
  public readonly popoverOffset!: number

  @Prop({ type: Number, required: true })
  public readonly sliceResults!: number

  @Prop({ type: Boolean, required: false })
  public readonly shouldWatchResize?: boolean

  public resultsContainer: HTMLElement | null = null

  public sameWidth = sameWidth

  public get shouldSliceResults (): boolean {
    return typeof this.sliceResults !== 'undefined' && this.sliceResults > 0
  }
}
export default SearchResultsDesktop
