import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'

import { AllowedContentType, ISiteService, NavigationItem, SiteServiceType } from '../../../../contexts'
import { ComponentsStructureConfig } from '../../../../support/mixins/StructureConfigurable.mixin.vue'
import { Inject } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { AuthMixin, IAuthMixin } from '../../../auth/shared'
import { BaseCartMixin, IBaseCart } from '../../../checkout/shared/mixins/base-cart.mixin'
import BaseWishListMixin from '../../../wishlist/shared/mixins/base.mixin'
import { Drawers } from '../../../auth/config/modals'
import { Drawers as CheckoutDrawers } from '../../../checkout/config'
import { ProfileTab, ProfileTabsServiceType, Service } from '../../../profile/services/profile-tabs'
import { RouteName as CheckoutRoute, RouteName } from '../../../checkout/routes'
import { RouteNames } from '../../../orders/routes'
import { RouteNames as AuthRouteNames } from '../../../auth/routes'
import { RouteNames as WishlistRouteNames } from '../../../wishlist/routes'

import { DrawerType, IDrawer } from '../../contracts/services'

import {
  NAVBAR_COMPONENT_KEY,
  NAVBAR_DESKTOP_DEFAULT_CONFIG,
  NAVBAR_MOBILE_DEFAULT_CONFIG, PossibleMobileMenuItems,
  StaticLink, StaticLinks
} from './Navbar.config'

import { CurrentLocale, MobileMenuItemDefinition, NavbarLogo, NavbarProps } from './Navbar.contracts'
import BodyMarginMixin from './partials/BodyMargin.mixin.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NavbarBaseMixin>({
  name: 'NavbarBaseMixin',
  created () {
    this.config = this.getComponentConfig(
      NAVBAR_COMPONENT_KEY,
      { ...NAVBAR_DESKTOP_DEFAULT_CONFIG, ...NAVBAR_MOBILE_DEFAULT_CONFIG }
    )
    this.constructBottomNav()
  }
})
export class NavbarBaseMixin extends Mixins<IAuthMixin & IBaseCart & BaseWishListMixin & BodyMarginMixin & StructureConfigurable>(
  AuthMixin,
  BaseCartMixin,
  BaseWishListMixin,
  BodyMarginMixin,
  StructureConfigurable
) {
  @Inject(DrawerType, false)
  public readonly drawerService?: IDrawer

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  @Inject(ProfileTabsServiceType)
  protected readonly profileTabsService!: Service

  @Prop({ type: Boolean, required: false, default: true })
  public readonly hasCart!: boolean

  @Prop({ type: [Object, Array], required: false })
  public readonly logo?: NavbarLogo | Array<NavbarLogo>

  @Prop({ type: Array, required: false })
  public readonly mainLinks?: NavbarProps['mainLinks']

  @Prop({ type: Array, required: false })
  public readonly externalLinks?: NavbarProps['externalLinks']

  @Prop({ type: Array, required: false })
  public readonly languagesLinks?: NavbarProps['languagesLinks']

  @Prop({ type: Array, required: false })
  public readonly profileLinks!: NavbarProps['profileLinks']

  @Prop({ type: String, required: false })
  public readonly profileTitle?: NavbarProps['profileTitle']

  @Prop({ type: Array, required: true })
  public readonly staticLinks!: Array<StaticLink>

  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasTopBar!: boolean

  @Prop({ type: String, required: false })
  public readonly siteAddress?: string

  @Prop({ type: Boolean, required: false, default: true })
  protected readonly hasActive!: boolean

  public config: ComponentsStructureConfig = {}
  public isPageWithoutMobileNav: boolean = false
  public menuOpen: number | null = null
  public nestedMenuOpen: boolean = false
  public profileHelperMenuOpen: boolean = false
  public selectedMenuItem: string | null = null

  /**
   * Reference for navigation.
   */
  @Ref('navigation')
  public readonly navigationRef?: HTMLElement

  @Watch('isMenuOpen')
  private onIsMenuOpenChange () {
    if (this.isMenuOpen) {
      this.profileHelperMenuOpen = false
    }
  }

  public get cartStrategy (): string {
    return this.getConfigProperty('cartStrategy')
  }

  public get demandMobileNavIcons (): AnyObject {
    return this.getConfigProperty('mobileNavIcons')
  }

  public get loginStrategy (): string {
    return this.getConfigProperty('loginStrategy')
  }

  public get shouldHideMobileNav (): boolean {
    return this.getConfigProperty<boolean>('shouldHideMobileNav')
  }

  public get showCartIconWithoutPrice (): boolean {
    return this.getConfigProperty('showCartIconWithoutPrice')
  }

  public get useDrawer (): boolean {
    return this.getConfigProperty<boolean>('useDrawer')
  }

  public get useSecondAuthDrawer (): boolean {
    return this.getConfigProperty('useSecondAuthDrawer')
  }

  public get accountLinkId (): string {
    return StaticLinks.account
  }

  public get currentLocale (): CurrentLocale | undefined {
    const locale = this.siteService.getActiveSite().locale

    if (!this.hasLanguages) {
      return
    }

    const foundLanguage = this.languagesLinks!
      .find((item) => item.secondaryLabel && item.secondaryLabel === locale)

    return foundLanguage ? {
      id: foundLanguage.secondaryLabel ?? '',
      icon: foundLanguage.icon ?? undefined,
      label: foundLanguage.label ? foundLanguage.label.toString() : ''
    } : undefined
  }

  public get hasSecondaryNav (): boolean {
    return !!this.externalLinks?.length || this.hasCart || this.hasLanguages
  }

  public get hasLanguages (): boolean {
    return Array.isArray(this.languagesLinks) && this.languagesLinks.length > 0
  }

  public get isMenuOpen (): boolean {
    return this.selectedMenuItem === 'menu' || this.selectedMenuItem === 'menuWithSearch'
  }

  public get isUserChatDefined (): boolean {
    if (typeof window === 'undefined') {
      return false
    }

    const USER_CHAR_FRAME_SELECTOR = '#usercom-launcher-dot-frame'
    const userChatFrame = document.querySelector<HTMLIFrameElement>(USER_CHAR_FRAME_SELECTOR)

    return !!userChatFrame
  }

  public get favouriteLinkId (): string {
    return StaticLinks.favouriteProducts
  }

  /**
   * Get profile page
   */
  public get profileRoute (): string {
    return `orders.${RouteNames.History}`
  }

  public get profileTabs (): Array<ProfileTab> {
    return this.profileTabsService.getTabs()
  }

  public handleCartClick (): void {
    if (this.cartStrategy === 'router') {
      this.$router.push({ name: `checkout.${RouteName.Cart}` })
      return
    }

    if (this.cartStrategy === 'drawer') {
      if (typeof this.drawerService === 'undefined') {
        return
      }

      this.drawerService.open(CheckoutDrawers.Cart)
    }
  }

  /**
   * Determines whether current item has children.
   */
  public hasChildren (item: NavigationItem): boolean {
    return Array.isArray(item.children) && item.children.length > 0
  }

  /**
   * Builds bottom nav items.
   */
  public constructBottomNav (): MobileMenuItemDefinition[] {
    let navigationItems = Object.values(PossibleMobileMenuItems)
    if (!this.isUserChatDefined) {
      setTimeout(() => {
        navigationItems.push({
          id: 'chat',
          label: 'chat',
          icon: {
            default: 'ChatIcon',
            active: 'ChatIcon'
          }
        })
      }, 2000)
    }
    if (!this.isServiceAvailable) {
      navigationItems = navigationItems.filter(item => {
        return item.id !== this.favouriteLinkId
      })
    }

    if (!this.hasCart) {
      navigationItems = navigationItems.filter(item => {
        return item.id !== 'cart'
      })
    }

    if (!Array.isArray(this.demandMobileNavIcons) && this.demandMobileNavIcons.length === 0) {
      return navigationItems
    }

    const filteredNavigationItems: MobileMenuItemDefinition[] = []

    navigationItems.forEach((mobileIcon) => {
      const foundItem = this.demandMobileNavIcons.find((demandMobileIcon: MobileMenuItemDefinition) => mobileIcon.id.toLowerCase() === demandMobileIcon.id.toLowerCase())
      if (!foundItem) {
        return
      }

      mobileIcon.icon.default = foundItem.icon.default
      mobileIcon.icon.active = foundItem.icon.active
      filteredNavigationItems.push({
        ...mobileIcon,
        order: foundItem.order
      })
    })

    return filteredNavigationItems.sort((a, b) => {
      return parseInt(a.order || '0') - parseInt(b.order || '0')
    })
  }

  /**
   * Handles item select drom menu.
   */
  public onMenuItemSelect (id: string): void {
    /** Emit event, handled by AddToCardModal/Drawer that should emit close action */
    this.eventBus.emit('app:mobile-nav-click', id)

    if (id === 'menu' || id === 'menuWithSearch') {
      this.$emit('hover', false)
    } else if (id === this.accountLinkId) {
      if (this.loginStrategy === 'router' && !this.isLoggedInUser) {
        this.$router.push({ name: `auth.${AuthRouteNames.Auth}` })
        return
      }

      if (this.isLoggedInUser) {
        if (this.useSecondAuthDrawer) {
          this.toggleOpenProfileHelperMenu()
        } else {
          this.$router.push({ name: `${this.profileRoute}` })
        }
      } else {
        this.openDrawerLogin()
      }
    } else if (id === 'cart') {
      if (typeof this.drawerService === 'undefined') {
        return
      }

      if (this.useDrawer) {
        this.drawerService.open(CheckoutDrawers.Cart)
      } else {
        this.$router.push({
          name: `checkout.${CheckoutRoute.Cart}`
        })
      }
    } else if (id === 'chat') {
      this.openChat()
    } else if ((id === 'logo' || id === 'home') && this.$route.path !== '/') {
      this.$router.push('/')
    } else if (id === this.favouriteLinkId) {
      this.$router.push({ name: `wishlist.${WishlistRouteNames.Wishlist}` })
    }

    if (this.selectedMenuItem && this.selectedMenuItem === id) {
      this.selectedMenuItem = null
    } else {
      this.selectedMenuItem = id
    }

    this.nestedMenuOpen = false
  }

  /**
   * Async function instead of getter fixes the bug with Navbar which is visible for a millisecond
   */
  public async checkIfIsPageWithoutMobileNav (): Promise<void> {
    const response = await this.$store.getters['content/response']

    if (!response) {
      this.isPageWithoutMobileNav = false
      return
    }

    this.isPageWithoutMobileNav = response.content.type === AllowedContentType.Product ||
      response.content.type === AllowedContentType.ProductCategory
  }

  public toggleOpenProfileHelperMenu (): void {
    this.profileHelperMenuOpen = !this.profileHelperMenuOpen
  }

  /**
   * Open drawer
   */
  public openDrawerLogin () {
    if (!this.drawerService) {
      return
    }

    this.drawerService.open(Drawers.Auth)
  }

  public openChat () {
    const el = document.querySelector<HTMLIFrameElement>('#usercom-launcher-dot-frame')

    const launcher = el?.contentDocument?.querySelector<HTMLDivElement>('.usercom-launcher-dot')
    if (launcher) {
      launcher.click()
    }
  }

  /**
   * Emits event that closes backdrop.
   */
  protected hideBackdrop () {
    this.$emit('unhover')
  }

  @Watch('hasActive')
  protected onHasActiveUpdate (val: boolean, oldValue: boolean): void {
    if (val === oldValue) {
      return
    }

    if (!val) {
      this.selectedMenuItem = null
    }
  }

  /**
   * Notifies parent Navbar with events.
   *
   * This method imitates @mouseenter & @mouseleave event that can
   * be handled in parent on @hover & @unhover respectively.
   */
  @Watch('selectedMenuItem')
  protected notify (id: string): void {
    if (id === 'menu' || id === 'search' || id === 'menuWithSearch') {
      this.$emit('hover', true)
    } else {
      this.$emit('unhover')
    }
  }

  @Watch('isLoggedInUser')
  protected onIsUserLoggedInUpdate (isLoggedIn: boolean): void {
    this.profileHelperMenuOpen = this.profileHelperMenuOpen && isLoggedIn
  }
}
