

























































































import { Component, Mixins, Prop } from 'vue-property-decorator'

import { ComponentsStructureConfig } from '../../../../../../support/mixins'
import { Inject } from '../../../../../../support'

import {
  FavouriteProductsServiceType,
  IFavouriteProductsService
} from '../../../../../products/contracts/services'

import { Loader } from '../../../../molecules/Loader'

import { SearchResults } from '../../../SearchResults'

import { NavbarBaseMixin } from '../../Navbar.mixin'
import { NavbarProps } from '../../Navbar.contracts'
import {
  NAVBAR_COMPONENT_KEY,
  NAVBAR_MOBILE_DEFAULT_CONFIG,
  NavbarTypes
} from '../../Navbar.config'

import MobileBottomMenu from '../MobileBottomMenu.vue'
import Logo from '../../../../molecules/Logo/Logo.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 *
 * @emits - [hover]: When element in bottom nav is selected
 * @emits - [unhover]: When element in bottom nav is unselected.
 */
@Component<NavbarMobile>({
  name: 'NavbarMobile',
  components: {
    Logo,
    Loader,
    MobileBottomMenu,
    SearchResults
  },
  created (): void {
    this.config = this.getComponentConfig(NAVBAR_COMPONENT_KEY, { ...NAVBAR_MOBILE_DEFAULT_CONFIG })
    this.checkIfIsPageWithoutMobileNav()
  }
})
export class NavbarMobile extends Mixins<NavbarBaseMixin>(NavbarBaseMixin) {
  @Prop({ type: Array, required: false })
  public readonly languagesLinks?: NavbarProps['languagesLinks']

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  /**
   * Determines component config.
   */
  public config: ComponentsStructureConfig = {}

  public searchResultsOpen: boolean = false
  public isSearchOpen: boolean = false

  public get showDynamicResults (): boolean {
    return this.getConfigProperty('showDynamicResults')
  }

  /**
   * Determines whether external links are present.
   */
  public get hasExternalLinks (): boolean {
    return typeof this.externalLinks !== 'undefined' && this.externalLinks.length > 0
  }

  /**
   * Determines navbar type.
   */
  public get navbarType (): string {
    return this.getConfigProperty('type')
  }

  /**
   * Determines whether navbar is type `withOpenSearch`.
   */
  public get withOpenSearchType (): boolean {
    return this.navbarType === NavbarTypes.WithOpenSearch
  }

  /**
   * Handles `destroy` lifecycle hook of SearchResults component.
   */
  public onDestroy (): void {
    this.hideBackdrop()
  }
}

export default NavbarMobile
