
































































































































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import { ModalConfig } from '@movecloser/front-core/lib/contracts/services'
import debounce from 'lodash/debounce'

import { logger } from '../../../../../../support'

import { NavigationItem } from '../../../../../../contexts'

import { Drawers } from '../../../../../auth/config/modals'
import { BaseCartMixin } from '../../../../../checkout/shared/mixins/base-cart.mixin'

import { ComponentsStructureConfig } from '../../../../../../support/mixins'

import { AuthMixin } from '../../../../../auth/shared'
import { RouteNames } from '../../../../../auth/routes'
import { RouteNames as WishlistRouteNames } from '../../../../../wishlist/routes'

import { Loader } from '../../../../molecules/Loader'

import { SearchResults } from '../../../SearchResults'

import {
  NAVBAR_COMPONENT_KEY,
  NAVBAR_DESKTOP_DEFAULT_CONFIG,
  NavbarTypes,
  StaticLink,
  StaticLinks
} from '../../Navbar.config'
import NavbarUser from '../NavbarUser.vue'
import {
  BaseWishListMixin
} from '../../../../../wishlist/shared/mixins/base.mixin'
import { NavbarBaseMixin } from '../../Navbar.mixin'

interface DebouncedFunc<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): ReturnType<T> | undefined
  cancel(): void
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<NavbarDesktop>({
  name: 'NavbarDesktop',
  components: { NavbarUser, SearchResults, Loader },
  created (): void {
    this.config = this.getComponentConfig(
      NAVBAR_COMPONENT_KEY,
      { ...NAVBAR_DESKTOP_DEFAULT_CONFIG }
    )
    this.onMenuEnter = debounce(this.openMenu.bind(this), 200)
    this.onMenuLeave = debounce(this.openClose.bind(this), 10)
  }
})
export class NavbarDesktop extends Mixins<NavbarBaseMixin & AuthMixin & BaseCartMixin & BaseWishListMixin>(
  NavbarBaseMixin,
  AuthMixin,
  BaseCartMixin,
  BaseWishListMixin
) {
  /**
   * Reference for navigation.
   */
  @Ref('navigation')
  public readonly navigationRef?: HTMLElement

  public readonly wishlistRouteNames = WishlistRouteNames

  public isSearchOpen: boolean = false

  public menuOpen: number | null = null

  public searchResultsOpen: boolean = false

  @Watch('menuOpen')
  public onMenuOpenUpdate () {
    if (this.menuOpen !== null) {
      this.searchResultsOpen = false
    }
  }

  /**
   * Determines component config.
   */
  public config: ComponentsStructureConfig = {}

  protected onMenuEnter:
    DebouncedFunc<(
      event: MouseEvent,
      isParent: boolean,
      index: number | null
    ) => void> | null = null

  protected onMenuLeave: DebouncedFunc<(event: MouseEvent, isParent: boolean) => void> | null = null

  /**
   * Get id of account static link.
   */
  public get accountStaticLinkId (): StaticLinks {
    return StaticLinks.account
  }

  public get favouriteLinkId (): string {
    return StaticLinks.favouriteProducts
  }

  /**
   * Get id of favourite static link.
   */
  public get favouriteStaticLinkId (): StaticLinks {
    return StaticLinks.favouriteProducts
  }

  public get filteredStaticLinks (): StaticLink[] {
    let navigationItems = this.staticLinks

    navigationItems = navigationItems.filter(item => {
      return item.id !== this.favouriteLinkId
    })

    return navigationItems
  }

  /**
   * Determines whether external links are present.
   */
  public get hasExternalLinks (): boolean {
    return typeof this.externalLinks !== 'undefined' && this.externalLinks.length > 0
  }

  public get isNameVisible (): string {
    return this.getConfigProperty('isNameVisible')
  }

  public get loginStrategy (): string {
    return this.getConfigProperty('loginStrategy')
  }

  public get navbarType (): string {
    return this.getConfigProperty('type')
  }

  public get showDynamicResults (): boolean {
    return this.getConfigProperty('showDynamicResults')
  }

  public get menuOpeningStrategyOnTablet (): string {
    return this.getConfigProperty('menuOpeningStrategyOnTablet')
  }

  public get siteName (): string {
    return this.siteService?.getActiveSite().name || ''
  }

  public get showCartQuantity (): boolean {
    return !!this.cart && this.cart.getTotalQuantity() > 0
  }

  public get withOpenSearchType (): boolean {
    return this.navbarType === NavbarTypes.WithOpenSearch
  }

  public callAction (staticLinkId: string) {
    switch (staticLinkId) {
      case this.favouriteStaticLinkId: {
        this.openDrawerLogin()
        break
      }

      case this.accountStaticLinkId: {
        if (this.loginStrategy === 'drawer') {
          this.openDrawerLogin()
          break
        }

        if (this.loginStrategy === 'router') {
          this.$router.push({ name: `auth.${RouteNames.Auth}` })
        }
        break
      }
      default: {
        logger('[NavbarDesktop]: Action is not defined.', 'debug')
      }
    }
  }

  /**
   * Determines whether current item has children.
   */
  public hasChildren (item: NavigationItem): boolean {
    return Array.isArray(item.children) && item.children.length > 0
  }

  public openMenu (event: MouseEvent, isParent: boolean, index: number | null = null) {
    this.menuOpen = index
    if (this.isSearchOpen) {
      this.isSearchOpen = false
    }
    this.$emit('hover', isParent)
  }

  public openClose (event: MouseEvent, isParent: boolean) {
    this.menuOpen = null
    this.$emit('unhover', isParent)
  }

  /**
   * Handles `om:mouseenter` event of element.
   */
  public onMouseEnter (event: MouseEvent, isParent: boolean, index = null): void {
    if (!this.onMenuEnter) {
      return
    }

    this.onMenuEnter(event, isParent, index)
  }

  /**
   * Handles `om:mouseleave` event of element.
   */
  public onMouseLeave (event: MouseEvent, isParent: boolean): void {
    if (!this.onMenuEnter || !this.onMenuLeave) {
      return
    }

    this.onMenuEnter.cancel()
    this.onMenuLeave(event, isParent)
  }

  /**
   * Open drawer modal
   */
  public openDrawerLogin (payload?: AnyObject, modalConfig?: ModalConfig): void {
    if (!this.drawerService) {
      return
    }

    this.drawerService.open(Drawers.Auth, payload, modalConfig)
  }
}

export default NavbarDesktop
